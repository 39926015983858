@use "variables.scss" as *;
.Root {
  width: 100%;
  position: fixed;
  top: 56px;
  bottom: 72px;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;

  @include iftablet {
    bottom: 0;
  }

  @include ifdesktop {
    display: none;
  }

  .Header {
    display: flex;
    gap: 6px;

    .Title {
      font-weight: 600;
      font-size: 20px;
      color: $black;
    }
  }

  .Routes {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 100%;

    .Route {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      color: $black;
    }

    .Divider {
      width: 100%;
      height: 1px;
      background-color: #f5f6f7;
    }
  }
}
