@use "variables.scss" as *;
.Root {
  list-style: none;
  display: flex;
  gap: 19px;
}

.MenuItem {
  padding: 4px 14px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #45494c;
  letter-spacing: 0.02em;
  cursor: pointer;
}

.MenuItemActive {
  composes: MenuItem;

  border-bottom: 4px solid $additional-color;
}

.MenuItemDisabled {
  composes: MenuItem;

  position: relative;
  cursor: inherit;
  color: rgba(#45494c, 0.4);

  &::after {
    position: absolute;
    top: 0px;
    content: "cкоро";
    background: #f5f5f5;
    border-radius: 22px;
    color: #9a41d1;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding: 1px 4px 3px;
  }
}

.MenuItemSoon {
  composes: MenuItem;
  position: relative;
  @include width-1280 {
    margin-right: 30px;
  }

  &::after {
    position: absolute;
    top: 0px;
    content: "cкоро";
    background: #f5f5f5;
    border-radius: 22px;
    color: #9a41d1;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding: 1px 4px 3px;
  }

  & a {
    color: inherit;
  }
}

.MenuItemProfile {
  composes: MenuItem;
  cursor: pointer;
  position: relative;

  &_lock {
    position: absolute;
    top: -6px;
    right: -13px;
    z-index: 1;

    display: inline-flex;
    padding: 6px;
    align-items: flex-start;
    border-radius: 31px;
    background: #FFF;
    box-shadow: 0px 3px 9px 0px rgba(65, 54, 32, 0.10), 0px 0px 1px 0px rgba(128, 128, 128, 0.15);
  }
}
