@use "variables.scss" as *;
.TariffsButton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  font-size: 12px;
  padding: 11px;
  height: 36px;

  &_orange,
  &_orange_md {
    background-color: $orange-color;
  }

  &_orange:hover,
  &_orange_md:hover {
    background: #ff5e00 !important;
  }

  &_orange:active,
  &_orange_md:active {
    background: #ff7e33 !important;
  }

  &_orange:disabled,
  &_orange_md:disabled {
    background: #ff7728 !important;
    opacity: 0.5;
  }

  &_orange {
    text-transform: uppercase;
  }

  &_orange_md {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 21px 16px;
    text-transform: unset;
  }

  &_blue {
    background-color: #e8f0f7;
    color: #4d566d;
  }

  &_blue:hover {
    background-color: #d5e4f1 !important;
    color: #4d566d !important;
  }

  &_blue:active {
    background: #f0f5fa !important;
    color: #4d566d !important;
  }

  &_blue:disabled {
    background: #e8f0f7 !important;
    color: #4d566d !important;
  }
}
