@use "variables.scss" as *;
.Root {
  min-height: 100vh;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  background: $main-background;
  min-height: 100vh;
}

.Header {
  display: flex;
  box-shadow: inset 0px -1px 0px #eaebec;
  background: $white-color;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0 16px;

  @include iftablet {
    height: 68px;
  }

  @include ifdesktop {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;

    display: grid; // Располагаем центральное меню по центру, иначе уедет
    grid-template-columns: 1fr auto 1fr;
    & > *:last-child {
      justify-self: end;
    }
  }
}

.Logo {
  width: 100%;
  max-width: 126px;
  height: 26px;
  background: url(../../../ui/assets/logo.svg) no-repeat left center;
  background-size: contain;
  flex-shrink: 0;

  @include iftablet {
    height: 40px;
    max-width: 175px;
  }
}

.Main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  //margin-bottom: 70px;

  @media (max-width: $tablet-width) {
    margin-bottom: 0;
  }

  @include ifdesktop {
    padding: 68px 0 0;
  }
}

// NO AUTH STATE
.ClientButton {
  margin-left: 4px;
  margin-right: 4px;
  padding-top: 4px;

  & svg {
    width: 32px;
    height: 32px;
  }
}

.UserDashboard {
  position: relative;
  align-items: center;
  display: flex;
  gap: 0 10px;
  margin-left: 30px;

  @include width-1280 {
    margin-left: 0;
  }

  @include ifdesktop {
    gap: 0 10px;
  }

  &__aboveBtns {
    position: absolute;
    top: -14px;
    left: 30px;
    height: 20px;
    display: none;
    @media (min-width: 768px) and (max-width: 894px) {
      display: block;
    }

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $additional-color;
    text-align: left;
  }

  &__subscription {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $additional-color;
    display: none;
    min-width: 82px;
    text-align: left;


    @media (min-width: 1280px) and (max-width: 1440px) {
      width: 50px;
    }
    @media (min-width: 888px) and (max-width: 962px) {
      width: 50px;
    }

  }

  &__subscription_show {
    display: block;
    @media (min-width: 768px) and (max-width: 894px) {
      display: none;
    }
    @include width-1280 {
      display: block;
    }
  }

  &__button {
    text-transform: initial !important;
  }

  &__button_hide {
    display: none;
  }

  &__button_ask_question {
    display: none;

    @include iftablet {
      display: block;
    }
  }

  &__button_signin {
    text-transform: capitalize;
  }
}

.Footer {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: $white-color;
  border-top: 1px solid #e1e3e6;
  z-index: 99999;

  @media (max-width: $tablet-width) {
    display: block;
  }
}
