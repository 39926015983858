@use "variables.scss" as *;
.Root {
  display: grid;

  @include iftablet {
    display: none;
  }

  position: fixed;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #e1e3e6;
  list-style: none;
  grid-template-columns: repeat(4, 1fr);
  height: 72px;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 9999;
}

.MenuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #45494c;
  cursor: pointer;
}

.MenuItemActive {
  composes: MenuItem;

  & svg {
    fill: #45494c;
  }
}

.MenuItemDisabled {
  composes: MenuItem;

  position: relative;
  cursor: inherit;
  color: rgba(#45494c, 0.4);

  &::after {
    position: absolute;
    top: -6px;
    right: 5px;
    content: "cкоро";
    background: #f5f5f5;
    border-radius: 22px;
    color: #9a41d1;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding: 1px 4px 3px;
  }

  & svg {
    opacity: 0.4;
  }
}

.MenuItemSoon {
  composes: MenuItem;

  position: relative;
  cursor: inherit;

  &::after {
    position: absolute;
    top: -6px;
    right: 5px;
    content: "cкоро";
    background: #f5f5f5;
    border-radius: 22px;
    color: #9a41d1;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding: 1px 4px 3px;
  }

  & a {
    color: inherit;
  }

  & svg {
    padding-top: 3px;
  }
}

.MenuItemProfile {
  composes: MenuItem;

  //position: relative;
  cursor: inherit;

  &_lock {
    position: absolute;
    top: -30px;
    right: -20px;
    z-index: 1;

    display: inline-flex;
    padding: 6px;
    align-items: flex-start;
    border-radius: 31px;
    background: #FFF;
    box-shadow: 0px 3px 9px 0px rgba(65, 54, 32, 0.10), 0px 0px 1px 0px rgba(128, 128, 128, 0.15);
  }
}

.Label {
  margin-top: 3px;
  position: relative;
}

.LabelActive {
  composes: Label;

  border-bottom: 2px solid #f56606;
}
