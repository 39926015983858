@use "variables.scss" as *;
.NotificationCircle {
  &__circle {
    background: #ffde7c;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    max-width: 10px;
    max-height: 10px;
  }

  &__circle_absolute {
    position: absolute;
    top: 0px;
    right: -8px;
  }

  &__text {
    font-weight: 600;
    font-size: 9px;
    letter-spacing: -0.18px;
    text-transform: uppercase;
    color: #45494c;
    display: flex;
    line-height: 11px;
  }
}
